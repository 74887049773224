import React from 'react'
import UserNavigation from './UserNavigation/UserNavigation'

export const AppNavigation = () => {
  return (
    <div>
      <UserNavigation/>
    </div>
  )
}
